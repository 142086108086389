import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import newlogo from "./images/newlogo.png";
import CloseIcon from '@mui/icons-material/Close';
import wait from "./images/wait.svg";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import site from "./images/site.png";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LoginIcon from '@mui/icons-material/Login';
import TuneIcon from '@mui/icons-material/Tune';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';
import {Helmet} from 'react-helmet';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import CodeIcon from '@mui/icons-material/Code';


const Tos = () => {

const user_info = JSON.parse(localStorage.getItem('user'));
const [matches, setMatches] = useState(window.matchMedia("(min-width: 768px)").matches);

const changeColor = (color) => {
	console.log(color);
	if(color === '#1dd1a1'){
			setStyle('bodyColor1');
	}
	if(color === '#ff6b6b'){
			setStyle('bodyColor2');
	}
	if(color === '#2e86de'){
			setStyle('bodyColor3');
	}
	if(color === '#f368e0'){
			setStyle('bodyColor4');
	}
	if(color === '#ff9f43'){
			setStyle('bodyColor5');
	}
}

const [toggle, setToggle] = useState('switch to register');
const navigate = useNavigate();
const [style, setStyle] = useState("bodyColor1");
const [show, setShow] = useState(false);
const [user, setUser] = useState({
		email: "",
		password: "",
		cnf_password: ""
});

const [login, setLogin] = useState({
		submit: false,
		status : false,
		invalid: false,
		verification: false,
		account : '',
		userID : 0,
		user_email: '',
		user_exist : false,
		user_exist_text : 'Email already exists' 
});

 const toggleMenu = () => {
		let navigation = document.querySelector('.navigation');
			let toggle = document.querySelector('.toggle');
			navigation.classList.toggle('active');
 }

 const handleSubmit = (e) => {
		e.preventDefault();
		
		console.log(user);
		if(user.email === '' || user.password === ''){
			alert('Please enter email and password');
            return;
		}
		if(user.password !== user.cnf_password){
			alert('Password and confirm password not matches');
            return;
		}
		
		setLogin({...login, submit : true});

		fetch('https://oneclickhuman.com/api_request/register', {
			mode:'cors', 
			method: 'POST',
			body: JSON.stringify({
				'email' : user.email,
				'password' : user.password
			}),
			headers: {
				 'Content-type': 'application/json; charset=UTF-8',
			}
		}).then(res => res.json())
			.then((json) => {
				 console.log(json);
				 if(json.id === 0){
                     setLogin({...login, user_exist : true}); 
                     setToggle('switch to login'); 
				 }else{
				    setLogin({...login, account : 'pending', userID : json.id, user_email: json.user_email});	
				 }
		});
 };

  const handleSubmitLogin = (e) => {
		e.preventDefault();
		
		console.log(user);
		if(user.email === '' && user.password === ''){
			alert('Please enter email and password');
            return;
		}
		setLogin({...login, user_exist : false}); 
		setLogin({...login, submit : true, invalid: false, verification: false});

		fetch('https://oneclickhuman.com/api_request/login', {
			mode:'cors', 
			method: 'POST',
			body: JSON.stringify({
				'email' : user.email,
				'password' : user.password
			}),
			headers: {
				 'Content-type': 'application/json; charset=UTF-8',
			}
		}).then(res => res.json())
			.then((json) => {
				 console.log(json);
				 if(json.login === 'success'){
						 setLogin({...login, account : 'active', userID : json.id, user_email: json.user_email, invalid: false});
						 localStorage.setItem('user', JSON.stringify({
						  	account : 'active',
						  	userID: json.id,
						  	user_email: json.user_email
						  }));
						 setTimeout(() => {
							setShow(false);
						 }, 500);

				 }else if(json.login === 'on-verification'){
				 	setLogin({...login, verification : true});
				 }else{
           setLogin({...login, invalid : true});
				 }
		});
 };

const signOut = (e) => {
	e.preventDefault(); 
	setLogin({...login, submit: false, status : false, invalid: false, account: '', userID: 0});
	localStorage.removeItem('user');
	var toast = document.getElementById("toast-logout");
  var progress = document.getElementById("progress-logout");
  toast.classList.add("active");
  progress.classList.add("active");
  setTimeout(() =>{
       toast.classList.remove("active");
  }, 5000);
  setTimeout(() =>{
       progress.classList.remove("active");
  }, 5300);
}

const userClick = (e) => {
	e.preventDefault();
   if(login.account !== 'active'){
		  setShow(true);
	}else{
    navigate("/account");
  }
}
const userClickMobile = (e) => {
    e.preventDefault();
    if(login.account !== 'active'){
        setShow(true);
        var element = document.getElementById("offcanvasNavbar");
        element.classList.remove("show");
        
        if(document.getElementsByClassName("offcanvas-backdrop")){
            var backdrope = document.getElementsByClassName("offcanvas-backdrop");
            backdrope[0].remove();
        }
    }
}

useEffect(() => {
   if (user_info) {
         setLogin({...login, account: user_info.account, userID: user_info.userID, user_email: user_info.user_email});
   }
}, [1]);


 return(
       <>

         <Helmet>
           <title>Privacy Policy - OneClickHuman</title>
           <meta name="description" content="" />
         </Helmet>

                    <div className="wrapper">
        <div className="header-wrapper">
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand gap-3">
                        <div className="topbar-logo-header d-none d-lg-flex">
                            <div className="">
                                <h4 className="logo-text">
                                  <a href="/">
                                    <img src={newlogo} className="newlogo"/>  
                                  </a>
                                </h4>
                            </div>
                        </div>
                        <div className="mobile-toggle-menu d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"><i className='bx bx-menu'></i></div>
         
         { matches === true ? 
               <div className="primary-menu">
                   <nav className="navbar navbar-expand-lg align-items-center">
                      <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="">
                                   { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                                </div>
                                <div className="">
                                    <h4 className="logo-text">
                                      <a href="/">
                                        <img src={newlogo} className="newlogo"/>  
                                      </a>
                                    </h4>
                                </div>
                            </div>
                          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/">
                                    <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Home</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                    <div className="parent-icon"><i className='bx bx-dollar'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Pricing</div>
                                </a>                                
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                    <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Help</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                    <div className="parent-icon"><i className='bx bxs-note'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Blog</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/developer/api" >
                                    <div className="parent-icon"><i className='bx bx-code'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">API</div>
                                </a>
                              </li>
                          </ul>
                        </div>
                      </div>
                  </nav>
            </div>
              :
              ''
             }

               { login.account === 'active' ?
                       <>
                       { matches === false ? 
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="/">
                              <img src={newlogo} className="newlogo"/>
                            </a>
                        </div>
                         :
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="user-info">
                                   <p className="user-name mb-0">{login.user_email}</p>  
                                </div>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={userClick}><i class="bx bx-user fs-5"></i><span>Account</span></a>
                                </li>
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={signOut}><i class="bx bx-log-out-circle"></i><span>Logout</span></a>
                                </li>
                            </ul>
                        </div>
                       }
                        </>
                        :
                        <>
                        { matches === false ? 
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="/">
                              <img src={newlogo} className="newlogo"/>
                            </a>
                        </div>
                        : 
                        <div className="user-box px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="#" role="button">
                                <div className="user-info">
                                 <button onClick={() => { setShow(true); setToggle('switch to login');}}><LoginIcon/> Login</button>
                                </div>
                            </a>
                        </div>
                        }
                        </>
                    }

                    </nav>
                </div>
            </header>

        { matches === false ? 
                <div className="primary-menu">
                   <nav className="navbar navbar-expand-lg align-items-center">
                      <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="">
                                   { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                                </div>
                                <div className="">
                                    <h4 className="logo-text">
                                      <a className="d-flex align-items-center nav-link gap-3" href="/">
                                        <img src={newlogo} className="newlogo"/>
                                      </a>
                                    </h4>
                                </div>
                            </div>
                          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/">
                                    <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Home</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                    <div className="parent-icon"><i className='bx bx-dollar'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Pricing</div>
                                </a>                                
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                    <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Help</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                    <div className="parent-icon"><i className='bx bxs-note'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Blog</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/developer/api">
                                    <div className="parent-icon"><i className='bx bx-code'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">API</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="#" onClick={userClickMobile}>
                                    <div className="parent-icon"><i className='bx bx-user'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">{ login.account === 'active' ? login.user_email : 'Sign in'}</div>
                                </a>
                              </li>
                          </ul>
                        </div>
                      </div>
                  </nav>
            </div>
              :
              ''
             }

           </div>


            <div className="page-wrapper">
            <div className="page-content">
                <div className="row">
                    <div className="col-xl-10 mx-auto">
                        <div className="card border-top border-0 border-4 border-white">
                            <div className="card-body p-0">



	 <AccountBox id={ matches === true ? "": "tool-mobile" }>
        <Heading>
          <TuneIcon/> <b>Terms of Service</b>	
        </Heading>

        <Content>
          <div className="innerAcc">
            <div>
               <h3>1. Acceptance of Terms</h3>
By using the services provided by Oneclickhuman.com (“the Service”), you agree to be bound by the following Terms of Service (“TOS”). If you do not agree to these terms, you may not access or use the Service.
<br></br><br></br>
<h3>2. Description of Service</h3>
Oneclickhuman.com is a tool that converts refines AI-generated content to make it better and sound as natural as human content.
<br></br><br></br>
<h3>3. User Obligations</h3>
By using the Service, you agree to:

Not reproduce, duplicate, copy, sell, or exploit any portion of the Service without explicit permission.
<br></br><br></br>
<h3>4. Intellectual Property</h3>
The Service and its original content, features, and functionality are owned by Oneclickhuman.com and are protected by international copyright, trademark, patent, trade secret, and other intellectual property or proprietary rights laws.
<br></br><br></br>
<h3>5. Termination</h3>
We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including, without limitation, if you breach the TOS.
<br></br><br></br>
<h3>6. Limitation of Liability</h3>
To the fullest extent permitted by law, Oneclickhuman.com shall not be liable for any indirect, incidental, special, consequential or punitive damages, or any loss of profits or revenues, whether incurred directly or indirectly, or any loss of data, use, goodwill or other intangible losses resulting from (a) your use or inability to use the Service; (b) any unauthorized access to or use of our servers and/or any personal information stored therein.
<br></br><br></br>
<h3>7. Changes to TOS</h3>
Oneclickhuman.com reserves the right to update or change the TOS at any time without notice. Your continued use of the Service after any modifications to the TOS will constitute your agreement to be bound by the modified TOS.
<br></br><br></br>
<h3>8. Governing Law</h3>
These Terms shall be governed by and construed in accordance with the laws of India, without regard to its conflict of law provisions.
<br></br><br></br>
<h3>9. Contact Information</h3>
For any questions regarding this TOS or the Service, please contact info@oneclickhuman.com.

Prohibition of Plagiarism and Academic Misconduct
<br></br><br></br>
<h3>10. Use for Ethical and Lawful Purposes</h3>
Users of Oneclickhuman.com agree to utilize the Service in an ethical manner. Specifically:

a. No Plagiarism: Users shall not use the Service to generate or produce content that plagiarizes or infringes upon the intellectual property rights of any third party, including copyrights, trademarks, or other proprietary rights. Users are responsible for ensuring that the content they generate using the Service is original and does not replicate existing materials in a manner that constitutes plagiarism.

b. No Academic Misconduct: Users are prohibited from using the Service to engage in any form of academic misconduct, including but not limited to submitting any content generated by the Service as their own original work for academic credit, evaluation, or any other academic purpose. It is the user's responsibility to abide by the academic integrity policies of their respective educational institutions.
<br></br><br></br>
<b>11. Consequences for Misuse: Any misuse of the Service, including generating plagiarized content or engaging in academic misconduct, can result in immediate termination of access to the Service, at the discretion of Oneclickhuman.com. Furthermore, users found in violation may be subject to other legal or academic consequences.</b>

            </div>
          </div>
        </Content>

	 </AccountBox>

	                       </div>
                    </div>
                  </div>
                </div>
            </div>
        </div>

         <div className="overlay toggle-icon"></div>
        <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
        <footer className="page-footer">
            <p className="mb-0">Copyright ©. All right reserved.</p>
        </footer>
   

        <div className="switcher-wrapper">
        <div className="switcher-btn"> <i className='bx bx-cog bx-spin'></i>
        </div>
        <div className="switcher-body">
            <div className="d-flex align-items-center">
                <h5 className="mb-0 text-uppercase" style={{fontSize: '1rem'}}>Change Background</h5>
                <button type="button" className="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr/>
            <p className="mb-0">Gaussian Texture</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme1"></li>
                <li id="theme2"></li>
                <li id="theme3"></li>
                <li id="theme4"></li>
                <li id="theme5"></li>
                <li id="theme6"></li>
              </ul>
               <hr/>
              <p className="mb-0">Gradient Background</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme7"></li>
                <li id="theme8"></li>
                <li id="theme9"></li>
                <li id="theme10"></li>
                <li id="theme11"></li>
                <li id="theme12"></li>
                <li id="theme13"></li>
                <li id="theme14"></li>
                <li id="theme15"></li>
              </ul>
        </div>
    </div>

	{ show === true ?
		 <div id="popup">
				<div id="login-form">
					{ toggle === 'switch to register' ?
						<form onSubmit={handleSubmit}>
						   <h3 style={{marginBottom: '7px'}}>Create New Account!</h3>
						   <p className="already">Already have an account? <span onClick={() => setToggle('switch to login')}>Login in</span></p>
                           <span className="closeLogin" onClick={() => {setLogin({...login, submit: false, status : false, invalid: false, verification: false, account : '', userID : 0, user_email: '', user_exist : false, user_exist_text : 'Email already exists'}); setShow(false); }}><CloseIcon/></span> 

							<div className="inputBox">
								<input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
								<span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
							</div>
							
							<div className="inputBox">
							   <input type="password" placeholder="Password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
							</div>

							<div className="inputBox">
							   <input type="password" placeholder="Confirm password" onChange={(e) => setUser({ ...user, cnf_password: e.target.value })} />
							</div>

							<button type="submit">SIGN UP</button>
							{ login.submit === true ?
								<p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
								: ''
							}
							{ login.account === 'pending' ?
								<p id="waiting" style={{color: '#fff'}}><img src={wait} />awaiting email verification....</p> 
								: ''
							}
							{ login.account === 'active' ?
								<p id="verfied" style={{color: '#fff'}}>Account activated</p> 
								: ''
							}
							<div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => setToggle('switch to login')}><LockIcon/> Login</span> 
							</div>
						</form>
						:  <form onSubmit={handleSubmitLogin}>     
                            <h3 style={{marginBottom: '7px'}}>Welcome back!</h3>
                            <span className="closeLogin" onClick={() => setShow(false)}><CloseIcon/></span> 

							<div className="inputBox">
								<input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
								<span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
							</div>
							
							<div className="inputBox">
								 <input type="password" placeholder="Enter your password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
							</div>
							<button type="submit" style={{background: '#9b5f07'}}>LOGIN</button>
							{ login.submit === true ?
								<p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
								: ''
							}
							{ login.user_exist === true ?
								<p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>{login.user_exist_text}</p> 
								: ''
							}
							{ login.account === 'active' ?
								<p id="verfied" style={{color: '#fff'}}>Login Success</p> 
								: ''
							}
							{ login.invalid === true ?
								<p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Invalid username or password</p> 
								: ''
							}
							{ login.verification === true ?
								<p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Email verification is pending</p> 
								: ''
							}
							<div className="form-footer">
                              <span className="left-foot">All fields are required.</span>
                              <span className="right-foot" onClick={() => setToggle('switch to register')}><AccountCircleIcon/> Sign Up</span> 
							</div>
						</form>
					}
				</div>
		 </div>
			: ''
}


</div>

{ matches === true ?
  <div className="toast" id="toast-logout">
    <div className="toast-content">
      <div className="message">
        <span className="message-text text-2"><DoneIcon/> Logged Out</span>
      </div>
    </div>
    <div className="progress" id="progress-logout"></div>
  </div>
    : ''
}
 
       </>
  	)
}

export default Tos;

const Progress = styled.div`
   width:100%;
   margin-bottom: 20px;

   .progress-top {
      display: flex;
      margin-bottom: 5px;

      p {
         width: 100%;
      }
      .progress-label {
         font-size: 14px;
      }
      .progress-value {
        text-align: right;
        font-size: 13px;
      }
    }

    .progress-btm {
      font-size: 12px;
      text-align: right;
      margin-top: 4px;
      color: #787878;
     }
`;

const SmallBtn = styled.div`
   display: flex;
   width:100%;

   button {
   	 position: static !important;
   	 margin-right: 10px;
     width: unset !important;
     height: unset !important;
     padding: 8px 12px;
     font-size: 13px; 
   }
`;

const Row = styled.div`
   width: 100%;
   display: flex;
   margin-bottom: 30px;

   .column-inner {
      width: 100%;
      padding: 0 15px;

      .note {
      	 font-size: 12px;
         background: #e7f4fd;
         color: #0f08ad;
         font-weight: 500;
         line-height: 20px;
         padding: 10px;
      }
   }
`;

const Card = styled.div`
   width:100%;
   padding: 20px;
   border-radius: 15px;
   background: #fff;
   box-shadow: 1px 1px 13px 1px #b8b5b5;
   position: relative;
   margin-bottom: 40px;

   textarea {
     min-height: 100px;
    height: 100px;
    border: 1px solid #eee;
    border-radius: 3px;
    margin-bottom:0;
    padding: 12px;
    font-size: 14px;
   }

   .features {
    font-size: 14px;
    line-height: 27px;
    color: #000;
   }

   .plan {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      border-radius: 8px;
      background: #000;
      color: #fff;
      padding: 5px 12px;
      margin-right: 5px;
    }
   }

   .plan-amnt {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 44px;

      span:nth-child(1) {
        font-size: 20px;
        vertical-align: top;
      }

      span:nth-child(2) {
        font-size: 50px;
        font-weight: 600;
        vertical-align: text-top;
      }

      span:nth-child(3) {
        font-size: 14px;
        color: #777;
        vertical-align: top;
      }
    }

   button {
    background: #2196f3;
    color: #fff;
    border: 0;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 145px;
    height: 40px;
    cursor: pointer;

     svg { vertical-align: middle; }
   }
`;

const Heading = styled.div`
    width: 100%;
    padding: 20px 15px;
    background: rgb(0 0 0 / 12%);
    font-size: 16px;
    color: #fff;

   svg { vertical-align: bottom; }
`;

const AccountBox = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 0;
    position: relative;
    height: 545px;

    h3 {
    font-size: 17px;
}
`;

const Content = styled.div`
   width:100%;
   overflow-x: hidden;

   .innerAcc {
   	  width: 103%;
   	  overflow-y: scroll;
   	  height: 450px;
   	   padding: 30px;
   }
`;

