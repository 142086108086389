
import './App.css';
import site from "./images/site.png";
import wait from "./images/wait.svg";
import quotaImg from "./images/quota.png";
import newlogo from "./images/newlogo.png";
import expired from "./images/expired.png";
import successImg from "./images/success.png";
import analyz from "./images/analyz.svg";
import stripe from "./images/stripe.svg";
import React, { useState, useEffect, useCallback, useMemo} from 'react';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import DoneIcon from '@mui/icons-material/Done';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import CloseIcon from '@mui/icons-material/Close';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import LockIcon from '@mui/icons-material/Lock';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { GrammarlyEditorPlugin } from '@grammarly/editor-sdk-react';
import ModeStandbyIcon from '@mui/icons-material/ModeStandby';
import LoginIcon from '@mui/icons-material/Login';
import Progressbar from 'react-js-progressbar';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import { useSearchParams, useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import EventSource from 'eventsource-polyfill';
import { sanitize, isSupported } from "isomorphic-dompurify";
import DOMPurify from 'isomorphic-dompurify';
import SlateEditor from './SlateEditor';
import SearchIcon from '@mui/icons-material/Search';
import styled from 'styled-components';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import DiamondIcon from '@mui/icons-material/Diamond';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CodeIcon from '@mui/icons-material/Code';
import InfoIcon from '@mui/icons-material/Info';


function App(props) {

const navigate = useNavigate();

// Start of user interface design
const confetti = require('canvas-confetti');
const [matches, setMatches] = useState(window.matchMedia("(min-width: 1000px)").matches);
useEffect(() => {
    window
    .matchMedia("(min-width: 768px)")
    .addEventListener('change', e => setMatches( e.matches ));
}, [1]);
const [matches_medium, setMatchesMedium] = useState(window.matchMedia("(max-width: 1000px)").matches);
const [matches_for_docs, setMatchesDocs] = useState(window.matchMedia("(max-width: 1300px)").matches);
useEffect(() => {
    window
    .matchMedia("(max-width: 1300px)")
    .addEventListener('change', e => setMatchesDocs( e.matches ));
}, [1]);

const [topbar_ul_matches, setTopbarUlMatches] = useState(window.matchMedia("(max-width: 950px)").matches);

const [style, setStyle] = useState("bodyColor1");
const changeColor = (color) => {
  if(color === '#1dd1a1'){
      setStyle('bodyColor1');
  }
  if(color === '#ff6b6b'){
      setStyle('bodyColor2');
  }
  if(color === '#2e86de'){
      setStyle('bodyColor3');
  }
  if(color === '#f368e0'){
      setStyle('bodyColor4');
  }
  if(color === '#ff9f43'){
      setStyle('bodyColor5');
  }
}
const toggleMenu = () => {
    let navigation = document.querySelector('.navigation');
    let toggle = document.querySelector('.toggle');
    navigation.classList.toggle('active');
}
// End of user interface design




  
  const [monthly_credits_exhausted, setMonthlyCreditsExhausted] = useState(false);
  const [lifetime_exhausted, setLifeTimeExhausted] = useState(false);
  const [monthly_onetime_credits_exhausted, setMonthlyOneTimeCreditsExhausted] = useState(false);
  const [showupdatebtn, setShowupdatebtn] = useState(false);
  
  
  
  
 


  const [discount, setDiscount] = useState('');
  const [premium, setPremium] = useState(false);
  const [plan, setPlan] = useState({monthly: true, onetime: false});
  const [subscription, setSubscription] = useState('');
  const [onetime_purchase, setOneTimePurchase] = useState('');



  
  const [promodal, setProModal] = useState(false);
  const [secondPro, setSecondPro] = useState(false);
  const [showback, setShowBack] = useState(true);
  const [countdown, setCountdown] = useState(""); 


// Language module
const [show_language, setShowLanguage] = useState(false);
const [language, setLanguage] = useState('English');
const changeLanguage = (lang) => {
  setLanguage(lang);
  setShowLanguage(false);
}
const showLanguage = () => {
  if(show_language === true){
      setShowLanguage(false);
   }else{
      setShowLanguage(true);
   }
}
// End Language module

// Start of modes module
const [showmodes, setShowModes] = useState(false);
const showModes = () => {
  if(showmodes === true){
      setShowModes(false);
   }else{
      setShowModes(true);
   }
}
const [mode, setMode] = useState({text: 'Premium Mode'});
const changeMode = (text) => {
   if(text === 'Premium Mode') {
       setMode({...mode, text: "Premium Mode"});    
       setShowModes(false);
   }
   if(text === 'Lightning Mode') {
      if(has_subscription){
          setMode({...mode, text: "Lightning Mode"});
          setShowModes(false);
      }else{
        alert('Please subscribe to our monthly plan to unlock Lightning/Unlimited Mode')
      }
   }
}
// End of modes module


// Start of reset module 
const [disable_reset, setDisableReset] = useState(false);
const [reset_editor, setResetEditor] = useState(false);
const resetInputText = () => {
    setTextInput('');
    setResetEditor(true);
    setRequest(0);
    setParaphrasedText('');
    setDescriptionButton(true);
    setHeadingHide(false);
} 
const resetEditorFalse = () => {
   setResetEditor(false);
}
// End of reset module 


// Start of user module - register, login, popup, 
const [show, setShow] = useState(false);
const [toggle, setToggle] = useState('switch to register');
const [user, setUser] = useState({
    email: "",
    password: "",
    cnf_password: ""
});
const [login, setLogin] = useState({
    submit: false,
    status : false,
    invalid: false,
    verification: false,
    account : '',
    userID : 0,
    user_email: '',
    user_exist : false,
    user_exist_text : 'Email already exists',
    credits_availbe: 0,
    subscription_amount: 0,
    subscription_renewal_date: ''
});
const [forgotPass, setForgotPass] = useState(false);
const [resetPass, setResetPass] = useState(false);
const [forgot_pass_email, setForgotPassEmail] = useState('');
const [forgot_pass_submit, setForgotPassSubmit] = useState(false);
const [resetPassOTP, setResetPassOTP] = useState('');
const [resetPassNew, setResetPassNew] = useState('');
const [resetPassConfirm, setResetPassConfirm] = useState('');
const [reset_pass_submit, setResetPassSubmit] = useState(false);
const [resetPassSuccess, setResetPassSuccess] = useState(false);
const [resetPassFailure, setResetPassFailure] = useState(false);
const handleSubmit = (e) => {
    e.preventDefault();
    if(user.email === '' || user.password === ''){
      alert('Please enter email and password');
            return;
    }
    if(user.password !== user.cnf_password){
      alert('Password and confirm password not matches');
            return;
    }
    setLogin({...login, submit : true});

    fetch('https://oneclickhuman.com/api_request/register', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : user.email,
        'password' : user.password
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         //console.log(json);
         if(json.id === 0){
              setLogin({...login, user_exist : true}); 
              setToggle('switch to login'); 
         }else{
            setLogin({...login, account : 'pending', userID : json.id, user_email: json.user_email}); 
            if(document.body.hasAttribute('style')){document.body.removeAttribute('style');}
         }
    });
};
const handleSubmitLogin = (e) => {
    e.preventDefault();

    if(user.email === '' && user.password === ''){
      alert('Please enter email and password');
            return;
    }
    setLogin({...login, user_exist : false}); 
    setLogin({...login, submit : true, invalid: false, verification: false});

    fetch('https://oneclickhuman.com/api_request/login', {
      mode:'cors',
      method: 'POST',
      body: JSON.stringify({
        'email' : user.email,
        'password' : user.password
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         //console.log(json);
         if(json.login === 'success'){
             setLogin({...login, account : 'active', userID : json.id, user_email : json.user_email, invalid: false});
             localStorage.setItem('user', JSON.stringify({
                account : 'active',
                userID: json.id,
                user_email: json.user_email
              }));
             setTimeout(() => {
              setShow(false);
             }, 500);

             if(document.body.hasAttribute('style')){document.body.removeAttribute('style');}

         }else if(json.login === 'on-verification'){
          setLogin({...login, verification : true});
         }else{
            setLogin({...login, invalid : true});
         }
    });
};
const signOut = (e) => {
  e.preventDefault(); 
  setLogin({...login, submit: false, status : false, invalid: false, account: '', userID: 0});
  setQuota({...quota,  number: 0, plan: 0, text: '', tooltip: ''});
  localStorage.removeItem('user');

    var toast = document.getElementById("toast-logout");
    var progress = document.getElementById("progress-logout");
    toast.classList.add("active");
    progress.classList.add("active");
    setTimeout(() =>{
       toast.classList.remove("active");
       window.location.reload();
    }, 1000);
    /*setTimeout(() =>{
       progress.classList.remove("active");
    }, 5300); */
}

const forgotPassword = () => {
   setForgotPass(true);
   setToggle("");
}
const sendOTP = (e) => {
   e.preventDefault();

   if(forgot_pass_email === ''){
       alert('Please enter your email');
       return;
   }
   setForgotPassSubmit(true);
   fetch('https://oneclickhuman.com/api_request/forgot_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setForgotPassSubmit(false);
                   setForgotPass(false);
                   setResetPass(true);
              }
    });
}
const handleResetPassword = (e) => {
   e.preventDefault();
   if(resetPassOTP === ''){
       alert('Please Enter OTP');
       return;
   }  
   if(resetPassNew === ''){
       alert('Please Enter new password');
       return;
   }
   if(resetPassConfirm === ''){
       alert('Please Enter confirm password');
       return;
   }
   if(resetPassNew !== resetPassConfirm){
       alert('New Password and Confirm Password does not match!');
       return;
   }

   setResetPassSubmit(true);
   setResetPassFailure(false);

      fetch('https://oneclickhuman.com/api_request/reset_password', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : forgot_pass_email,
        'new_password' : resetPassNew,
        'otp' : resetPassOTP
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
              //console.log(json); 
              if(json.status === 'success'){
                   setResetPassSuccess(true);
                   setResetPassSubmit(false);
              }else{
                 setResetPassSubmit(false);
                 setResetPassFailure(true);
              }
    });  
}
const userClick = (e) => {
  e.preventDefault();
   if(login.account !== 'active'){
      setShow(true);
  }else{
     navigate("/account");
  }
}
const userClickMobile = (e) => {
    e.preventDefault();
    if(login.account !== 'active'){
        setShow(true);
        var element = document.getElementById("offcanvasNavbar");
        element.classList.remove("show");
        
        if(document.getElementsByClassName("offcanvas-backdrop")){
            var backdrope = document.getElementsByClassName("offcanvas-backdrop");
            backdrope[0].remove();
        }
    }
}
const resendEmailVerification = () => {
   console.log(login.userID);
   fetch('https://oneclickhuman.com/api_request/resend_email_verification', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : login.user_email,
        'user_id' : login.userID,
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         if(json.status === 'success'){
              alert('Verification Email is resent.');
         }
      });
}
// End of user module - register, login, popup, 



// Start of get user details with useEffect hook after login or if already logged in (localstorage)
// quota to decresed -> 1 = free, 2 = monthly, 3 = onetime, 4 = lifetime
const [quota_to_decresed, setQuotaToDecresed] = useState(1);
const [has_subscription, setHaveSubscription] = useState(false);
const [quota, setQuota] = useState({number: 0, plan: 0, text: '', tooltip: ''});
const [account_status, setAccountStatus] = useState({ 
                                              credits_availbe: 0,
                                              subscription_amount: 0, 
                                              onetime_credit: 0,
                                              onetime_plan: 0,
                                              lifetime_credits: 0,
                                              lifetime_plan: 0,
                                              subscription_renewal_date: '',
                                            });
useEffect(() => {
   // Sidebar toggle with plain javascript
   let toggleBtn = document.querySelector('.toggleBtn');
   let container = document.querySelector('.container-des');
   if(toggleBtn !== null){
      toggleBtn.onclick = function(){
      container.classList.toggle('active');
      }
   }
   
   if(login.account === 'active'){ 
     fetch('https://oneclickhuman.com/api_request/checkquota', {
      mode:'cors', 
      method: 'POST',
        body: JSON.stringify({
        'user_id' : login.userID,
      }),
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
      }
     }).then(res => res.json())
     .then((json) => {
      //console.log(json);
      var next_date = json.subscription_renewal_date;
      var next_arr = next_date.split('T');
      var next_date_real = next_arr[0];
      setAccountStatus({...account_status, 
         credits_availbe: json.credits_availbe,
         onetime_credit: json.onetime_credit,
         onetime_plan: json.onetime_plan,
         lifetime_credits: json.lifetime_credits,
         lifetime_plan: json.lifetime_plan,
         subscription_amount: json.subscription_amount, 
         subscription_renewal_date: next_date_real});
       //console.log(json);
         let currentDate = new Date().toJSON().slice(0, 10);
         let lifetime_next_refill_date = json.lifetime_next_refill_date.split('T')[0];
         let lifetime_credits = json.lifetime_credits;

        if(json.is_lifetime_active === 1){
            if(lifetime_next_refill_date === currentDate){
              lifetime_credits = json.lifetime_plan;
              fetch('https://oneclickhuman.com/api_request/update_lifetime_refill_date', {
                mode:'cors', 
                method: 'POST',
                body: JSON.stringify({
                  'user_id' : login.userID,
                }),
                headers: {
                 'Content-type': 'application/json; charset=UTF-8',
                }
              }).then(res => res.json())
                .then((json) => {
                   // console.log(json.status); 
              });
           }
        }

             if(json.subscrption_status === 1 && json.onetime_plan > 0){
                  setHaveSubscription(true);
                  setPlan({...plan, monthly: false, onetime: true});
                  //console.log('both');
                  if(json.credits_availbe > 1000 && json.onetime_credit > 0){
                       setQuota({...quota, number: json.credits_availbe, plan: json.monthly_plan, text: 'Monthly Balance', tooltip: 'Number of Articles you can Humanize every Month.'});
                       setQuotaToDecresed(2);
                  }else if(json.credits_availbe > 1000){
                    setQuota({...quota, number: json.credits_availbe, plan: json.monthly_plan, text: 'Monthly Balance', tooltip: 'Number of Articles you can Humanize every Month.'});
                    setLifeTimeExhausted(true);
                    setHeightDecresed(true);
                    setQuotaToDecresed(2);
                  }else if(json.onetime_credit > 0){
                     setQuota({...quota, number: json.onetime_credit, plan: json.onetime_plan, text: 'Onetime Balance', tooltip: 'Number of Articles you can Humanize. Lifetime Validity'});
                     setMonthlyCreditsExhausted(true);
                     setHeightDecresed(true);
                     setQuotaToDecresed(3);
                  }else{
                     setMonthlyOneTimeCreditsExhausted(true); 
                     setHeightDecresed(true);
                     if(json.is_lifetime_active === 1 && lifetime_credits > 0){
                          setQuota({...quota, number: lifetime_credits, plan: json.lifetime_plan, text: 'Word Balance(LTD)', tooltip: 'Number of articles you can Humanize . Refills Monthly'});
                          setQuotaToDecresed(4);
                     }else{
                        if(json.quota > 0){
                          setQuota({...quota, number: json.quota, plan: 700, text: 'Daily Balance', tooltip: 'Number of articles you can Humanize. Refills at 12:00 EST'});
                          setQuotaToDecresed(1);
                        }else{
                          setQuota({...quota, number: json.quota, plan: 700, text: 'Balance - ', tooltip: 'Number of articles you can Humanize. Refills at 12:00 EST'});
                          setShowupdatebtn(true);
                        }
                     }
                } 
             }else if(json.subscrption_status === 1){
                 setHaveSubscription(true);
                 setPlan({...plan, monthly: false, onetime: true});
                if(json.credits_availbe > 0){
                    setQuota({...quota, number: json.credits_availbe, plan: json.monthly_plan, text: 'Monthly Balance', tooltip: 'Number of Articles you can Humanize every Month.'});
                    setQuotaToDecresed(2);
                  }else{
                     setMonthlyCreditsExhausted(true); 
                     setHeightDecresed(true);
                     if(json.is_lifetime_active === 1 && lifetime_credits > 0){
                          setQuota({...quota, number: lifetime_credits, plan: json.lifetime_plan, text: 'Balance(LTD)', tooltip: 'Number of articles you can Humanize . Refills Monthly'});
                          setQuotaToDecresed(4);
                     }else{
                        if(json.quota > 0){
                          setQuota({...quota, number: json.quota, plan: 700, text: 'Daily Balance', tooltip: 'Number of articles you can Humanize. Refills at 12:00 EST'});
                          setQuotaToDecresed(1);
                        }else{
                          setQuota({...quota, number: json.quota, plan: 700, text: 'Balance - ', tooltip: 'Number of articles you can Humanize. Refills at 12:00 EST'});
                          setShowupdatebtn(true);
                        }
                     }
                  }
             }else if(json.onetime_plan > 0){
                 if(json.onetime_credit > 0){
                      setQuota({...quota, number: json.onetime_credit, plan: json.onetime_plan, text: 'Onetime Balance', tooltip: 'Number of Articles you can Humanize. Lifetime Validity'});    
                      setQuotaToDecresed(3);
                  }else{
                     setLifeTimeExhausted(true);
                     setHeightDecresed(true);
                     if(json.is_lifetime_active === 1 && lifetime_credits > 0){
                          setQuota({...quota, number: lifetime_credits, plan: json.lifetime_plan, text: 'Balance(LTD)', tooltip: 'Number of articles you can Humanize . Refills Monthly'});
                          setQuotaToDecresed(4);
                     }else{
                        if(json.quota > 0){
                          setQuota({...quota, number: json.quota, plan: 700, text: 'Daily Balance', tooltip: 'Number of articles you can Humanize . Refills at 12:00 EST'});
                          setQuotaToDecresed(1);
                        }else{
                          setQuota({...quota, number: json.quota, plan: 700, text: 'Balance - ', tooltip: 'Number of articles you can Humanize . Refills at 12:00 EST'});
                          setShowupdatebtn(true);
                        }
                     }
                  }
             }else{
                setDiscount('open');
                setHeightDecresed(true);
                if(json.is_lifetime_active === 1 && lifetime_credits > 0){
                          setQuota({...quota, number: lifetime_credits, plan: json.lifetime_plan, text: 'Balance(LTD)', tooltip: 'Number of articles you can Humanize . Refills Monthly'});
                          setQuotaToDecresed(4);
                }else{
                   if(json.quota > 0){
                        setQuota({...quota, number: json.quota, plan: 700, text: 'Daily Balance', tooltip: 'Number of articles you can Humanize . Refills at 12:00 EST'});
                        setQuotaToDecresed(1);
                    }else{
                       setQuota({...quota, number: json.quota, plan: 700, text: 'Balance - ', tooltip: 'Number of articles you can Humanize . Refills at 12:00 EST'});
                       setShowupdatebtn(true);
                    }
                }
             }

             if(json.quota === 0){
                 var timeArr = json.current_date.split("-");
                 var newd = parseInt(timeArr[2]) + 1;
                 var newtime = timeArr[0] + '-' + timeArr[1] + '-' + newd;
                 var countDownDate = new Date(`${newtime} 00:00:00`).getTime();

                 var x = setInterval(function() {
                 var dt = new Date();
                 var edt = dt.toLocaleString('en-US', {timeZone: 'America/New_York'});
                 var now = new Date(edt).getTime();
                 var distance = countDownDate - now;
                 var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                 hours = ("0" + hours).slice(-2);
                 var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                 minutes = ("0" + minutes).slice(-2);
                 var seconds = Math.floor((distance % (1000 * 60)) / 1000);
                 seconds = ("0" + seconds).slice(-2);
                 var pendingTime = hours + ":" + minutes + ":" + seconds;
                 setCountdown(pendingTime);
                  if(distance < 0) {
                     clearInterval(x);
                     pendingTime = "EXPIRED";
                  }
                }, 1000);       
              } 
         });
    }

   if(login.account === 'pending'){
       const sse = new EventSource(`https://oneclickhuman.com/api_request/sse/${login.userID}`);
       sse.onmessage = function (event) {
        //console.log(event.data);
        if(event.data == 1){
          setLogin({...login, account : 'active'});
          localStorage.setItem('user', JSON.stringify({
            account : 'active',
            userID: login.userID,
            user_email: login.user_email
            }));
            setTimeout(() => {
            setShow(false);
          }, 2000);
               
          this.close();
        }    
       };
   }

 }, [login]);
// End of get user details with useEffect hook after login or if already logged in (localstorage)



// Start of content conversion module - paraphrasing
const [request_process, setRequestProcess] = useState(0);
const [textInput, setTextInput] = useState('');
const [request, setRequest] = useState(0);
const [paraphrasedText, setParaphrasedText] = useState('');
let msgCnt = 0;
var source;
const free_word_limit = 300;
const [TextBottomRight, setTextBottomRight] = useState(false);

// Under Construction Modal
const [underConstruction, setUnderConstruction] = useState(false);

function paraphrase2() {

  //setUnderConstruction(true);
  //return;

    //setRequest(2);
    //return;

  let outputRes = '';
  let quota_used = 0;
  setTextBottomRight(false);
  
  if(request === 1){
       return;
  }
  if(login.account !== 'active') {
     setShow(true);
     return;
  }
  if(textInput === ''){
     alert("Please enter text");
     return;
  }
  if(words < 30){
     alert("Please enter more than 30 words");
     return;
  } 
  if(quota.plan === 700){
      if(words > free_word_limit){
         alert('Words limit is exceeded!');
         return;
      }
  }else{
     if(words > 1500){
         alert('Words limit is exceeded!');
         return;
     }
  }
 /* if(words > quota.number){
    alert('The number of words you entered is more than your balance');
    return;
  } */
  if(TosChecked === false){
      alert('Please accept the terms of service.');
      return;
  }

  if(matches === false){
     var link = document.getElementById('myclick');
     link.click();
  }

  let frequency = 0;
  let presence = 0;
  let top_p = 0;
  let temp = 0;
  let filterText = '';
  //let model = 'gpt-4-turbo';

  switch(mode.text){
    /*case 'Quality Mode':
    filterText = DOMPurify.sanitize(textInput, {ALLOWED_TAGS: ['h1','h2', 'h3', 'h4', 'h5', 'h6', 'u', 'i', 'li', 'ol', 'br', 'a', 'ul', 'p'], FORBID_ATTR: ['style', 'dir', 'id', 'class', 'data-slate-node']});
    filterText = filterText.replace(/&nbsp;/g, "");
    filterText = filterText.replace(/<p>\s*?<\/p>/g, ''); 
    break; */

    case 'Premium Mode':
    filterText = DOMPurify.sanitize(textInput, {ALLOWED_TAGS: ['h1','h2', 'h3', 'h4', 'h5', 'h6', 'u', 'i', 'li', 'ol', 'br', 'a', 'ul', 'p'], FORBID_ATTR: ['style', 'dir', 'id', 'class', 'data-slate-node']});
    filterText = filterText.replace(/&nbsp;/g, "");
    filterText = filterText.replace(/<p>\s*?<\/p>/g, '');
    break;

    case 'Lightning Mode':
    filterText = DOMPurify.sanitize(textInput, {ALLOWED_TAGS: ['h1', 'h2', 'h3', 'h4', 'h5', 'u', 'i', 'br', 'a', 'p', 'b'], FORBID_ATTR: ['style', 'dir', 'id', 'class', 'data-slate-node']});
    filterText = filterText.replace(/&nbsp;/g, "");
    filterText = filterText.replace(/<h1>/g, "<p>");
    filterText = filterText.replace(/<\/h1>/g, "</p>");
    filterText = filterText.replace(/<h2>/g, "<p>");
    filterText = filterText.replace(/<\/h2>/g, "</p>");
    filterText = filterText.replace(/<h3>/g, "<p>");
    filterText = filterText.replace(/<\/h3>/g, "</p>");
    filterText = filterText.replace(/<h4>/g, "<p>");
    filterText = filterText.replace(/<\/h4>/g, "</p>");
    filterText = filterText.replace(/<h5>/g, "<p>");
    filterText = filterText.replace(/<\/h5>/g, "</p>");
    filterText = filterText.replace(/<h6>/g, "<p>");
    filterText = filterText.replace(/<\/h6>/g, "</p>");
    filterText = filterText.replace(/<p>\s*?<\/p>/g, '');
    break;
  }
  //return;

fetch('https://oneclickhuman.com/api_request/checkquota', {
       mode:'cors', 
       method: 'POST',
         body: JSON.stringify({
         'user_id' : login.userID,
       }),
       headers: {
        'Content-type': 'application/json; charset=UTF-8',
       }
     }).then(res => res.json())
       .then((json) => {

          if(json.quota > 0 || json.credits_availbe > 0 || json.onetime_credit > 0 || mode.text === 'Lightning Mode') {
              
              let paid_user = false;
              if(json.credits_availbe > 0 || json.onetime_credit > 0 || mode.text === 'Lightning Mode'){
                  paid_user = true;
              }else{
                paid_user = false;
              }
                 quota_used = json.quota_used;
                 setRequest(2);
                 setRequestProcess(1);
                 document.getElementById("paraphrase").disabled = true;
                 setDisableReset(true);
    
                 var Filter = require('bad-words');
                 var customFilter = new Filter({ placeHolder: " "});
                 filterText = customFilter.clean(filterText);
                // console.log(filterText);
                 let text_sent_ai = filterText; 
                // console.log(text_sent_ai);return;
                 let text_string = JSON.stringify({data: text_sent_ai});
  
            fetch('https://oneclickhuman.com/api_request/prompt_batch', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({is_live_mode: true, paid_user: paid_user, prompt: text_sent_ai, language: language, mode: mode.text}),
            })
            .then(response => response.text())
            .then(promptId => {
                if (source) {
                    source.close();
                }
                source = new EventSource("https://oneclickhuman.com/api_request/completion_batches/" + promptId);
                source.onmessage = function(event) {
                var dt = JSON.parse(event.data);
                
         // Update quota at the start of paraphrasing
         if(msgCnt === 0 && mode.text === 'Premium Mode'){
             fetch('https://oneclickhuman.com/api_request/updatequota', {
                mode:'cors', 
                method: 'POST',
                body: JSON.stringify({
                  'user_id' : login.userID,
                  'quota_to_decresed': quota_to_decresed,
                  'decreased_words': words,
                }),
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                }
             }).then(res => res.json())
               .then((json) => {
                     setQuota({...quota, number: json.quota_decreased});
                     
                     if(json.quota_decreased > 0 && json.quota_decreased < 1000){
                         if(quota_to_decresed === 2 && account_status.onetime_credit > 0){
                             setQuota({...quota, number: account_status.onetime_credit, plan: account_status.onetime_plan, text: 'Onetime Balance', tooltip: 'Number of Articles you can Humanize. Lifetime Validity'});        
                             setQuotaToDecresed(3);
                         }
                     }

                     if(json.quota_decreased === 0){

                          if(quota.plan !== 700){
                               if(quota_to_decresed === 2){
                                   if(account_status.onetime_credit > 0){
                                       setQuota({...quota, number: account_status.onetime_credit, plan: account_status.onetime_plan, text: 'Onetime Balance', tooltip: 'Number of Articles you can Humanize. Lifetime Validity'});        
                                       setQuotaToDecresed(3); 
                                   }else if(account_status.lifetime_credits > 0){
                                      setQuota({...quota, number: account_status.lifetime_credits, plan: account_status.lifetime_plan, text: 'Balance(LTD)', tooltip: 'Number of articles you can Humanize . Refills Monthly'});
                                      setQuotaToDecresed(4);
                                   }else{
                                      setQuota({...quota, number: 700, plan: 3, text: 'Daily Word Balance', tooltip: 'Number of articles you can Humanize . Refills at 12:00 EST'}); 
                                      setQuotaToDecresed(1);
                                   }
                                   setMonthlyCreditsExhausted(true);
                                   setHeightDecresed(true);
                               }
                               if(quota_to_decresed === 3){
                                   if(account_status.lifetime_credits > 0){
                                      setQuota({...quota, number: account_status.lifetime_credits, plan: account_status.lifetime_plan, text: 'Balance(LTD)', tooltip: 'Number of articles you can Humanize . Refills Monthly'});
                                      setQuotaToDecresed(4);
                                   }else{
                                     setQuota({...quota, number: 700, plan: 3, text: 'Daily Word Balance', tooltip: 'Number of articles you can Humanize . Refills at 12:00 EST'}); 
                                     setQuotaToDecresed(1);
                                   }
                                   setLifeTimeExhausted(true);
                                   setHeightDecresed(true);
                               }
                               if(quota_to_decresed === 4){
                                   setQuota({...quota, number: 700, plan: 3, text: 'Daily Word Balance', tooltip: 'Number of articles you can Humanize . Refills at 12:00 EST'}); 
                                   setHeightDecresed(true);
                                   setQuotaToDecresed(1);
                               }
                          }else{
                             setShowupdatebtn(true);
                          } 
                     }
                });
        }

    // When conversion is completed
    if (dt.msg === '[DONE]') {
         source.close();

      /* if(outputRes.substring(0,2) === '1>'){
            outputRes = outputRes.replace(/^.{2}/g, '<h1>');
       }
       if(outputRes.substring(0,2) === '2>'){
            outputRes = outputRes.replace(/^.{2}/g, '<h2>');
       }
       if(outputRes.substring(0,2) === '3>'){
            outputRes = outputRes.replace(/^.{2}/g, '<h3>');
       } 
       if(outputRes.substring(0,2) === '4>'){
            outputRes = outputRes.replace(/^.{2}/g, '<h4>');
       } 
       if(outputRes.substring(0,2) === '5>'){
            outputRes = outputRes.replace(/^.{2}/g, '<h5>');
       }
       if(outputRes.substring(0,2) === '6>'){
            outputRes = outputRes.replace(/^.{2}/g, '<h6>');
       }       
       if(outputRes.charAt(0) === '>'){
            outputRes = outputRes.replace('>', '');
       } */
        
         setParaphrasedText(outputRes);

         document.getElementById("paraphrase").disabled = false; 
         setDisableReset(false);
         setRequestProcess(0);
         var myCanvas = document.createElement('canvas');
         document.body.appendChild(myCanvas);
         var myConfetti = confetti.create(myCanvas, {resize: true,useWorker: true});
         myConfetti({
            particleCount: 100,
            spread: 40,
            origin: { y: 0.6 }
         });

         setTimeout(() => {
            setTextBottomRight(true);
            myCanvas.remove();  
         }, 1500);

        /* fetch('https://oneclickhuman.com/api_request/add_record', {
                mode:'cors', 
                method: 'POST',
                body: JSON.stringify({
                  'user_id' : login.userID,
                  'user_email' : login.user_email,
                  'mode' : mode.text,
                  'input' : filterText,
                  'output' : outputRes,
                  'words_used' : getCount(filterText),
                  'quota_used' : quota_used + 1
                }),
                headers: {
                  'Content-type': 'application/json; charset=UTF-8',
                }
             }).then(res => res.json())
               .then((json) => {
                    
             }); */

    }else{

           if(dt.msg !== undefined){
                // let ms = dt.msg.replace(":", ",");
                // ms = ms.replace(";", ".");
                // ms = ms.replace("-", "");
                // ms = ms.replace("—", "");
                // outputRes = outputRes + ms; 

                let ms = dt.msg.replace("`", "");
                ms = ms.replace("< ", "<");
                ms = ms.replace(" >", ">");
                ms = ms.replace(" /", "/");
                ms = ms.replace("/ >", "/>");
                ms = ms.replace(/\.([^\s])/g, '. $1');
                outputRes = outputRes + ms;
           }

          // if(msgCnt < 1){
            //  setParaphrasedText('<img style="width: 90%; height: 60px;" src="https://oneclickhuman.com/api-assets/images/textloader.svg" />');
           // }

            if(msgCnt < 1){
              setParaphrasedText('<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 50 && msgCnt < 53) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 100 && msgCnt < 103) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 140 && msgCnt < 143) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 190 && msgCnt < 193) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 240 && msgCnt < 243) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 290 && msgCnt < 293) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 345 && msgCnt < 348) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 390 && msgCnt < 393) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 440 && msgCnt < 443) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 495 && msgCnt < 498) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 545 && msgCnt < 548) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 590 && msgCnt < 593) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 650 && msgCnt < 653) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 700 && msgCnt < 703) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 750 && msgCnt < 753) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 800 && msgCnt < 803) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 850 && msgCnt < 853) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 900 && msgCnt < 903) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 950 && msgCnt < 953) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1000 && msgCnt < 1003) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1050 && msgCnt < 1053) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1100 && msgCnt < 1103) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1150 && msgCnt < 1153) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1150 && msgCnt < 1153) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1200 && msgCnt < 1203) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1250 && msgCnt < 1253) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1300 && msgCnt < 1303) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1350 && msgCnt < 1353) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1400 && msgCnt < 1403) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1450 && msgCnt < 1453) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1500 && msgCnt < 1503) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1550 && msgCnt < 1553) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            if(msgCnt > 1600 && msgCnt < 1603) {
              setParaphrasedText(outputRes + '<div class="main-item"><div class="animated-background"></div><div class="animated-background"></div><div class="animated-background"></div></div>');
            }
            msgCnt++;
          }
        };
      });
    }else{
       // if daily quota found 0
       setProModal(true);
       setSecondPro(false);
       setShowBack(true);
       var timeArr = json.current_date.split("-");
       var newd = parseInt(timeArr[2]) + 1;
       var newtime = timeArr[0] + '-' + timeArr[1] + '-' + newd;
       var countDownDate = new Date(`${newtime} 00:00:00`).getTime();
       var x = setInterval(function() {
       var dt = new Date();
       var edt = dt.toLocaleString('en-US', {timeZone: 'America/New_York'});
       var now = new Date(edt).getTime();
       var distance = countDownDate - now;
       var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
       hours = ("0" + hours).slice(-2);
       var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
       minutes = ("0" + minutes).slice(-2);
       var seconds = Math.floor((distance % (1000 * 60)) / 1000);
       seconds = ("0" + seconds).slice(-2);
       var pendingTime = hours + ":" + minutes + ":" + seconds;
       setCountdown(pendingTime);
       if(distance < 0) {
            clearInterval(x);
            pendingTime = "EXPIRED";
        }
      }, 1000); 
    }
  });
}
// End of content conversion module - paraphrasing


  
const selectText = (nodeId) => {
  const node = document.getElementById(nodeId);
  var toast = document.getElementById("toast-copy");
  var close = document.getElementById("toast-close-copy");
  var progress = document.getElementById("progress-copy");
  const selection = window.getSelection();
  const range = document.createRange();
  range.selectNodeContents(node);
  selection.removeAllRanges();
  selection.addRange(range);
  document.execCommand("copy");
  selection.removeAllRanges();
  toast.classList.add("active");
  progress.classList.add("active");
  setTimeout(() =>{
    toast.classList.remove("active");
  }, 5000);
  setTimeout(() =>{
    progress.classList.remove("active");
  }, 5300);
}
const copyContent = () =>{
  selectText('result');
}

const [searchParams] = useSearchParams();
useEffect(() => {  
   const user_info = JSON.parse(localStorage.getItem('user'));
   if (user_info) {
     setLogin({...login, account: user_info.account, userID: user_info.userID, user_email: user_info.user_email});
   }else{
     let emailVerified = searchParams.get('emailVerified');
      if(emailVerified === 'true'){
          setShow(true);
          setToggle('switch to login');
      }
   } 
},[1]);



const [CountWarning, setCountWarning] = useState(false);
const [DescriptionButton, setDescriptionButton] = useState(true);
function getCount(str) {
 return str.split(' ').filter(function(num) {
  return num != ''
 }).length;
}
const backspace = (val) => {
 // console.log(val);
  var offsetHeight = document.getElementById('InputWrapper').offsetHeight;
  //console.log(offsetHeight);
  if(offsetHeight > 500){
    setDescriptionButton(false);
    setHeadingHide(true);
  }else{
    setDescriptionButton(true);
    setHeadingHide(false);
  }

  setTextInput(val);

  let filterText = DOMPurify.sanitize(val, {ALLOWED_TAGS: [], FORBID_ATTR: ['style', 'dir', 'id', 'class']});
  filterText = filterText.replace(/&nbsp;/g, "");
  var countWord = getCount(filterText);
  setWordCount(countWord);
  var wordSpan = document.getElementById("word-count");
    if(wordSpan !== null){
      if(quota.plan === 700){
             if(countWord > free_word_limit){
               document.getElementById("word-count").style.color = 'red';
               setCountWarning(true);

               var toast = document.getElementById("toast-word-limit");
               var progress = document.getElementById("progress-word-limit");
               toast.classList.add("active");
               progress.classList.add("active");
               setTimeout(() =>{
                 toast.classList.remove("active");
               }, 10000);
               setTimeout(() =>{
                 progress.classList.remove("active");
               }, 10000);

             }else{
                document.getElementById("word-count").style.color = '#fff';
                setCountWarning(false);
             }
        }else{
           if(countWord > 1500){
            document.getElementById("word-count").style.color = 'red';
            setCountWarning(true);
          }else{
             document.getElementById("word-count").style.color = '#fff';
             setCountWarning(false);
          }
        }
    } 
}

const [words, setWordCount] = useState(0);
const [heading_hide, setHeadingHide] = useState(false);
useEffect(() => { 
   // console.log(textInput);
  var offsetHeight = document.getElementById('InputWrapper').offsetHeight;
  //console.log(offsetHeight);
  if(offsetHeight > 500){
    setDescriptionButton(false);
    setHeadingHide(true);
  }else{
    setDescriptionButton(true);
    setHeadingHide(false);
  }

  let filterText = DOMPurify.sanitize(textInput, {ALLOWED_TAGS: [], FORBID_ATTR: ['style', 'dir', 'id', 'class']});
  filterText = filterText.replace(/&nbsp;/g, "");

  var countWord = getCount(filterText);
  setWordCount(countWord);
  var wordSpan = document.getElementById("word-count");

    if(wordSpan !== null){
      if(quota.plan === 700){
             if(countWord > free_word_limit){
               document.getElementById("word-count").style.color = 'red';
               setCountWarning(true);

               var toast = document.getElementById("toast-word-limit");
               var progress = document.getElementById("progress-word-limit");
               toast.classList.add("active");
               progress.classList.add("active");
               setTimeout(() =>{
                 toast.classList.remove("active");
               }, 10000);
               setTimeout(() =>{
                 progress.classList.remove("active");
               }, 10000);

             }else{
                document.getElementById("word-count").style.color = '#fff';
                setCountWarning(false);
             }
        }else{
           if(countWord > 1500){
            document.getElementById("word-count").style.color = 'red';
            setCountWarning(true);
          }else{
             document.getElementById("word-count").style.color = '#fff';
             setCountWarning(false);
          }
        }
     }

 },[textInput]);

const checkToolHeight = (val) => {
   var offsetHeight = document.getElementById('InputWrapper').offsetHeight;
  //console.log(offsetHeight);
  if(offsetHeight > 500){
    setDescriptionButton(false);
    setHeadingHide(true);
  }else{
    setDescriptionButton(true);
    setHeadingHide(false);
  } 

  setTextInput(val);

  let filterText = DOMPurify.sanitize(val, {ALLOWED_TAGS: [], FORBID_ATTR: ['style', 'dir', 'id', 'class']});
  filterText = filterText.replace(/&nbsp;/g, "");
  //console.log(filterText);

 setTimeout(function(){
    var countWord = getCount(filterText);
   // console.log('Word count: ' + countWord);
    setWordCount(countWord);
    var wordSpan = document.getElementById("word-count");

    if(wordSpan !== null){
      if(quota.plan === 700){
             if(countWord > free_word_limit){
               document.getElementById("word-count").style.color = 'red';
               setCountWarning(true);

               var toast = document.getElementById("toast-word-limit");
               var progress = document.getElementById("progress-word-limit");
               toast.classList.add("active");
               progress.classList.add("active");
               setTimeout(() =>{
                 toast.classList.remove("active");
               }, 10000);
               setTimeout(() =>{
                 progress.classList.remove("active");
               }, 10000);

             }else{
                document.getElementById("word-count").style.color = '#fff';
                setCountWarning(false);
             }
        }else{
           if(countWord > 1500){
            document.getElementById("word-count").style.color = 'red';
            setCountWarning(true);
          }else{
             document.getElementById("word-count").style.color = '#fff';
             setCountWarning(false);
          }
        }
     }
     }, 0);
}
const [height_decresed, setHeightDecresed] = useState(false);
const inputCallback = (val) => {
  setTextInput(val);
}
const [placeholder, setPlaceHolder] = useState(true);
const [TosChecked, setTosChecked] = useState(true);
const handleTosChange = event => {
    setTosChecked(event.target.checked);
};



const [products, setProducts] = useState([]);
const [price_show, setPrice] = useState(0);
const [discount_price_show, setDiscountPrice] = useState(0);
const [price_show_onetime, setPriceOnetime] = useState(0);

useEffect(() => {
   fetch('https://oneclickhuman.com/api_request/get_prices', {
      method: 'GET'
    }).then(res => res.json())
      .then((json) => {
        //console.log(json.products);
        setProducts(json.products);
        let filterProdSubscrption = json.products.filter((product) => product.currency === 'usd' && product.is_subscription === 1);
        setSubscription(filterProdSubscrption[0].price_id);
        let filterProdOnetime = json.products.filter((product) => product.currency === 'usd' && product.is_subscription === 0);
        setOneTimePurchase(filterProdOnetime[0].price_id);
    });
}, [1]);

useEffect(() => {
  let filterProd = products.filter((product) => product.price_id === subscription);
  if(filterProd.length !== 0){
      setPrice(filterProd[0].amount);
  }
}, [subscription]);

useEffect(() => {
  let filterProd = products.filter((product) => product.price_id === onetime_purchase);;
  if(filterProd.length !== 0){
      setPriceOnetime(filterProd[0].amount);
  }
}, [onetime_purchase]);

const [promocode, setPromoCode] = useState('PAB50BA');
const [promo_status, setPromoStatus] = useState(true);

const [coupons, setCoupons] = useState([]);
useEffect(() => {
   fetch('https://oneclickhuman.com/api_request/get_coupon', {
      method: 'GET'
    }).then(res => res.json())
      .then((json) => {
        setCoupons(json.coupons);
    });

}, [1]);

const [discount_applied, setDiscountApplied] = useState({value: 50, status: true});
const [promocode_applied_text, setPromocodeAppliedText] = useState('Auto Applied 50% Off (Launch Offer)');
const applyCoupon = () => {
  let filterCoupon = coupons.filter((coupon) => coupon.code === promocode);
     if(filterCoupon.length !== 0){
         setDiscountApplied({...discount_applied, value: filterCoupon[0].discount, status: true });
         setPromocodeAppliedText(`${filterCoupon[0].discount}% Discount has been applied`);
     }else{
       alert('Invalid Coupon');
     }
}



// Start of payment success page module
const [successPage, setSuccessPage] = useState(false);
const [sub_credits, setSubCredits] = useState(0);
const [sub_amnt, setSubAmount] = useState(0);
const [success_currency, setSuccessCurrency] = useState('');
const [IsSubscriptionPlan, setIsSubscriptionPlan] = useState(false);
useEffect(() => {
  let param = searchParams.get('success');
  let subcrdt = searchParams.get('plan');
  let subamnt = searchParams.get('amnt');
  let subscriptionPlan = searchParams.get('subscription');
  let currency = searchParams.get('currency');

  if(param === 'true'){
    setSuccessPage(true);
    setSubCredits(subcrdt);
    setSubAmount(subamnt);
    setSuccessCurrency(currency);
    if(subscriptionPlan === 'true'){
        setIsSubscriptionPlan(true);
    }else{
       setIsSubscriptionPlan(false);
    }

   fetch('https://oneclickhuman.com/api_request/update_payment_react', {
      mode:'cors', 
      method: 'POST',
      body: JSON.stringify({
        'email' : login.user_email,
        'plan' : subcrdt,
        'subscription' : subscriptionPlan,
        'currency' : currency,
        'amnt' : subamnt,
      }),
      headers: {
         'Content-type': 'application/json; charset=UTF-8',
      }
    }).then(res => res.json())
      .then((json) => {
         console(json.status);
    });

  }
}, [1]);
// End of payment success page module


return (
    <>

    <div className="wrapper">
        <div className="header-wrapper">
            <header>
                <div className="topbar d-flex align-items-center">
                    <nav className="navbar navbar-expand gap-3">
                        <div className="topbar-logo-header d-none d-lg-flex">
                            <div className="">
                                <h4 className="logo-text">
                                  <a href="/">
                                    <img src={newlogo} className="newlogo"/>  
                                  </a>
                                </h4>
                            </div>
                        </div>
                        <div className="mobile-toggle-menu d-block d-lg-none" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar"><i className='bx bx-menu'></i></div>

            { matches === true ? 
                <div className="primary-menu">
                   <nav className="navbar navbar-expand-lg align-items-center">
                      <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="">
                                   { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                                </div>
                                <div className="">
                                    <h4 className="logo-text">
                                      <a href="/">
                                       <img src={newlogo} className="newlogo"/>  
                                      </a>
                                    </h4>
                                </div>
                            </div>
                          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/">
                                    <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Home</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                    <div className="parent-icon"><i className='bx bx-dollar'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Pricing</div>
                                </a>                                
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                    <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Help</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                    <div className="parent-icon"><i className='bx bxs-note'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Blog</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/developer/api">
                                    <div className="parent-icon"><i className='bx bx-code'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">API</div>
                                </a>
                              </li>
                          </ul>
                        </div>
                      </div>
                  </nav>
            </div>
              :
              ''
             }

               { login.account === 'active' ?
                       <>
                       { matches === false ? 
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="/">
                              <img src={newlogo} className="newlogo"/>
                            </a>
                        </div>
                         :
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link dropdown-toggle gap-3 dropdown-toggle-nocaret" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                <div className="user-info">
                                   <p className="user-name mb-0">{login.user_email}</p>  
                                </div>
                            </a>
                            <ul class="dropdown-menu dropdown-menu-end">
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={userClick}><i class="bx bx-user fs-5"></i><span>Account</span></a>
                                </li>
                                <li><a className="dropdown-item d-flex align-items-center" href="#" onClick={signOut}><i class="bx bx-log-out-circle"></i><span>Logout</span></a>
                                </li>
                            </ul>
                        </div>
                       }
                        </>
                        :
                        <>
                        { matches === false ? 
                        <div className="user-box dropdown px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="/">
                              <img src={newlogo} className="newlogo"/>
                            </a>
                        </div>
                        : 
                        <div className="user-box px-3">
                            <a className="d-flex align-items-center nav-link gap-3" href="#" role="button">
                                <div className="user-info">
                                 <button onClick={() => { setShow(true); setToggle('switch to login');}}><LoginIcon/> Login</button>
                                </div>
                            </a>
                        </div>
                        }
                        </>
                    }

                    </nav>
                </div>
            </header>

        { matches === false ? 
                <div className="primary-menu">
                   <nav className="navbar navbar-expand-lg align-items-center">
                      <div className="offcanvas offcanvas-start" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
                        <div className="offcanvas-header border-bottom">
                            <div className="d-flex align-items-center">
                                <div className="">
                                   { /*** <img src="assets/images/logo-icon.png" className="logo-icon" alt="logo icon"> ****/}
                                </div>
                                <div className="">
                                    <h4 className="logo-text">
                                      <a className="d-flex align-items-center nav-link gap-3" href="/">
                                        <img src={newlogo} className="newlogo"/>
                                      </a>
                                    </h4>
                                </div>
                            </div>
                          <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body">
                          <ul className="navbar-nav align-items-center flex-grow-1">
                            <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/">
                                    <div className="parent-icon"><i className='bx bx-home-alt'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Home</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/pricing">
                                    <div className="parent-icon"><i className='bx bx-dollar'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Pricing</div>
                                </a>                                
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/help">
                                    <div className="parent-icon"><i className='bx bx-question-mark'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Help</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/blog">
                                    <div className="parent-icon"><i className='bx bxs-note'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">Blog</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="/developer/api">
                                    <div className="parent-icon"><i className='bx bx-code'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">API</div>
                                </a>
                              </li>
                              <li className="nav-item">
                                <a className="nav-link dropdown-toggle-nocaret" href="#" onClick={userClickMobile}>
                                    <div className="parent-icon"><i className='bx bx-user'></i>
                                    </div>
                                    <div className="menu-title d-flex align-items-center">{ login.account === 'active' ? login.user_email : 'Sign in'}</div>
                                </a>
                              </li>
                          </ul>
                        </div>
                      </div>
                  </nav>
            </div>
              :
              ''
             }

           </div>

{ discount === 'open' ?
    <div id="discount-top">
      <p>Get Unlimited Usage in Lightning mode with our Subscription! <span onClick={() => {setProModal(true); setShowBack(false); setSecondPro(true);}}>Upgrade now</span></p>
      <span id="discount-close" onClick={() => {setHeightDecresed(false);setDiscount('');}}><CloseIcon/></span> 
      <span className="discount-icon"><InfoIcon/></span>
    </div>
    : ''
  }

  { monthly_credits_exhausted === true ?
    <div id="discount-top">
      <p>Monthly balance have been exhusted. <a style={{color: '#fff'}} href="https://billing.stripe.com/p/login/eVa6s857m2iX8FyeUU" target="_blank">Upgrade</a> your plan or <span onClick={() => {setProModal(true); setShowBack(false); setSecondPro(true);}}>purchase</span> Onetime words.</p>
      <span id="discount-close" onClick={() => {setMonthlyCreditsExhausted(false); setHeightDecresed(false);}}><CloseIcon/></span> 
      <span className="discount-icon"><InfoIcon/></span>
    </div>
    : ''
  }

  { monthly_onetime_credits_exhausted === true ?
    <div id="discount-top">
      <p>Premium balance have been exhusted. <a style={{color: '#fff'}} href="https://billing.stripe.com/p/login/eVa6s857m2iX8FyeUU" target="_blank">Upgrade</a> your plan or <span onClick={() => {setProModal(true); setShowBack(false); setSecondPro(true);}}>purchase</span> Onetime words.</p>
      <span id="discount-close" onClick={() => {setMonthlyOneTimeCreditsExhausted(false); setHeightDecresed(false); }}><CloseIcon/></span> 
      <span className="discount-icon"><InfoIcon/></span>
    </div>
    : ''
  }

  { lifetime_exhausted === true && has_subscription === false ?
    <div id="discount-top">
      <p>Onetime balance have been exhausted. <a style={{color: '#fff'}} href="https://billing.stripe.com/p/login/eVa6s857m2iX8FyeUU" target="_blank">Upgrade</a> your plan or <span onClick={() => {setProModal(true); setShowBack(false); setSecondPro(true);}}>purchase</span> additional Onetime words.</p>
      <span id="discount-close" onClick={() => {setLifeTimeExhausted(false);setHeightDecresed(false);}}><CloseIcon/></span> 
      <span className="discount-icon"><InfoIcon/></span>
    </div>
    : ''
  }


        <div className="page-wrapper">
            <div className="page-content">
                <div className="row">
                    <div className="col-xl-10 mx-auto">

    <h1 id="h1">
      <span className="realistic-marker-highlight">AI to Human Content Converter</span>
    </h1>
    <svg xmlns="//www.w3.org/2000/svg" version="1.1" className="svg-filters" style={{display: 'none'}}>
     <defs>
       <filter id="marker-shape">
        <feTurbulence type="fractalNoise" baseFrequency="0 0.15" numOctaves="1" result="warp" />
        <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="30" in="SourceGraphic" in2="warp" />
       </filter>
     </defs>
    </svg>


                        <div className="card border-top border-0 border-4 border-white">
                            <div className="card-body p-0">

                                { successPage === true ?
      <div id="success-page">
        <div id="success-inner">
          <div className="success-col left-side">
            <ThumbUpIcon />
            <h3>Payment Successful</h3>
            <p style={{fontSize: '15px'}}>Congratulations, we have received your payment, please find the details of your purchase below.</p>
            <div id="succ-table">
              <div id="table-head">
                <p><b>Details</b></p>
                <p><b>Words</b></p>
                <p><b>Amount</b></p>
              </div>
              <div id="table-content">
                <p>{IsSubscriptionPlan === true ? 'Monthly Membership' : 'Onetime plan'}</p>
                <p>{sub_credits*1000} {IsSubscriptionPlan === true ? '/Month' : ''}</p>
                <p>{success_currency}{sub_amnt}</p>
              </div>
            </div>
            <a id="go-to-tool" href="/">Go to tool</a>
          </div>
          <div className="success-col right-side">
            <img src={successImg} style={{width: '100%', maxWidth: '450px'}}/>
          </div>
        </div> 
    </div>
      : ''
   }

                            
<div id={ matches === true ? 'tool' : 'tool-mobile' } className={successPage === true ? 'toolshow' : ''}>
   <div id="top-bar">
       <span id="mode">
         <span id="mode-title"><ModeStandbyIcon/> Mode: </span>
         <button onClick={showModes}>{mode.text === 'Premium Mode' ? 'Premium' : 'Lightning (Unlimited)'}</button>
         { showmodes === true ? 
           <span id="mode-dropdown">
             <span className="tooltip" onClick={() => changeMode('Premium Mode')}>Premium<span className="tooltiptext">95% Accuracy , Formatted results.</span></span>
             <span className="tooltip" onClick={() => changeMode('Lightning Mode')}>Lightning (Unlimited)🔥{has_subscription ? '' : <LockIcon style={{color: '#828282', fontSize: '15px', verticalAlign: 'top'}}/>}<span className="tooltiptext">85% Accuracy, Lightning Fast & Unlimited usage.</span></span>
           </span>
         : ''
         }
       </span>

       <span id="language">
         <button onClick={showLanguage}>{language}</button>
         { show_language === true ? 
         <span id="language-dropdown">
           <span onClick={() => changeLanguage('Bulgarian')}>Bulgarian</span>
           <span onClick={() => changeLanguage('English')}>English</span> 
           <span onClick={() => changeLanguage('French')}>French</span>
           <span onClick={() => changeLanguage('German')}>German</span>
           <span onClick={() => changeLanguage('Hindi')}>Hindi</span>
           <span onClick={() => changeLanguage('Indonesian')}>Indonesian</span>
           <span onClick={() => changeLanguage('Japanese')}>Japanese</span>
           <span onClick={() => changeLanguage('Portuguese')}>Portuguese</span>
           <span onClick={() => changeLanguage('Polish')}>Polish</span>
           <span onClick={() => changeLanguage('Romanian')}>Romanian</span>
           <span onClick={() => changeLanguage('Russian')}>Russian</span>
           <span onClick={() => changeLanguage('Spanish')}>Spanish</span>  
         </span>
         : ''}
       </span>

{ !topbar_ul_matches ?
      <>
      <ul id="topbar-ul">
         <li>{mode.text === 'Premium Mode' ? <DoneIcon/> : <CloseIcon/> } Retains Formatting</li>
         <li><DoneIcon/> Perfect Grammar</li>
         <li><DoneIcon/> High Readability Score</li>
       </ul>
       <a id="documentation" href="/documentation"><ArticleOutlinedIcon/> Docs</a>
  </>
  :
 ''
}
       <span className="tooltip" id="tooltip-topbar">{ disable_reset === true ? <RestartAltIcon style={{color: '#999', cursor: 'no-drop'}}/> : <RestartAltIcon onClick={resetInputText}/>}<span className="tooltiptext">Reset</span></span> 
   </div>
   <div id="row">
     <div className="column" id="left-column">
       <div id="InputWrapper">
          <SlateEditor focus={true} inputCallback={inputCallback} resetEditor={reset_editor} resetEditorFalse={resetEditorFalse} ToolHeightCallback={checkToolHeight} backspace={backspace}/>
          { placeholder === true ?
                 <div id="placeholder" onClick={() => setPlaceHolder(false)}>
                   <h4>Insert your AI content here to make it Undetectable</h4> 
                   <br></br>You can add content directly from any AI tool, no need to process it or do anything, this tool will not only humanize the content but also make it sound better.
                   <br></br><br></br>Press the button "Make it Human" after pasting or writing the content 
             </div> 
             : '' 
               }
         </div>
       <div id="bottom-left">
          { login.account === 'active' ?
           <>
           { quota.plan === 700 || monthly_credits_exhausted === true ?
             <p id="small-text-left-bottom">
               <DiamondIcon style={{
                 fontSize: '14px', 
                 color: '#fdb114', 
                 verticalAlign: 'sub',
                 marginRight: '4px',
               }}/>
               <span style={{
                  textDecoration: 'underline',
                  cursor: 'pointer'
                }} 
                onClick={() => {setProModal(true); setShowBack(false); setSecondPro(true);}}>
                Upgrade
               </span> to remove Word limit & improve conversion quality
            </p>
             : ''
           }
            <span id="quota">
              { has_subscription && mode.text === 'Lightning Mode' ? 
                <span className="quota-col">
                  <AccessTimeIcon/>
                  <span>Lightning Mode</span> <br></br>
                  <span><small>Unlimited Words</small></span>
                </span>
               : 
              <span className="quota-col">
                <AccessTimeIcon/>
                <span>{quota.text} {showupdatebtn === true ? <>{quota.number}</> : '' }</span> <br></br>
                { showupdatebtn === true ?
                  <button onClick={() => {setProModal(true); setSecondPro(true);}} className="myupgrade">Buy Words</button>
                  :
                <>  
                <span><b>{quota.number} Words</b></span> 
                <span className="tooltip">
                  <InfoOutlinedIcon/>
                  <span className="tooltiptext">{quota.tooltip}</span>
                </span> 
                </>
                }
              </span>
             }

              <span className="quota-col">
                <SearchIcon/>
                <span>Words</span><br></br>
                <span id="word-count">
                <b>{words}/{quota.plan === 700 ? free_word_limit : '1500'}</b>
                { CountWarning === true ?
                 <span className="tooltip"><ErrorOutlineIcon style={{color: 'red'}}/>
                    <span className="tooltiptext warning-count">Words limit exceeded. Upgrade.</span>
                  </span>
                  : ''
                 }
                </span>
              </span>
              <span className="quota-col tos">
                <input
                 type="checkbox"
                 name="tos"
                 onChange={handleTosChange}
                 checked={TosChecked}
                />
                <span>I AGREE TO THE <a href="/tos" target="_blank">TOS</a> (NO ACADEMIC MISUSE)</span>
              </span>
            </span>
              { matches_medium === true ?
                <button id="paraphrase" type="button" className={login.account === 'active' ? 'paraphrase-loggedin' : ''} onClick={paraphrase2}><i></i><span>{ request_process === 1 ? <span class="dot-pulse"></span> : 'Humanize'}</span></button>
              :
                <button id="paraphrase" type="button" className={login.account === 'active' ? 'paraphrase-loggedin' : ''} onClick={paraphrase2}><i></i><span>{ request_process === 1 ? <span class="dot-pulse"></span> : 'Make it Human'}</span></button>
              }
            </>
                 : 
                 <span id="quota" style={{width: '100%', position: 'relative'}}>
                   <span className="quota_login" onClick={() => {setShow(true); setToggle('switch to login'); }}>Login</span> 
                   <span className="quota_login"> | </span>
                   <span className="quota_login" onClick={() => {setShow(true); setToggle('switch to register'); }}>Sign Up</span>
                   <button id="paraphrase" type="button" style={{position: 'absolute', top: '-8px', right: '2px'}} onClick={() => {setShow(true); setToggle('switch to login'); }}>Humanize</button>
                 </span>
          }

       </div>
     </div>
     <div className="column" id="right-column">
      { request === 2 ? 
          <>
          <div id="result-warpper">
            <div id="result" className={ height_decresed === true ? 'tool-height-decresed' : ''} contentEditable={true} suppressContentEditableWarning={true} dangerouslySetInnerHTML={{__html: paraphrasedText}} />
          </div>
          <div id="right-bottom">
             <div>
              { mode.text !== 'Premium Mode' && TextBottomRight === true ?
                <span id="TextBottomRight">Finding the tone too informal? Try <span onClick={() => changeMode('Premium Mode')}>Premium Mode</span></span>
                : ''
              }
               <span className="tooltip" onClick={copyContent}><ContentCopyIcon/><span className="tooltiptext">Copy</span></span>
             </div>
            </div>
            </>
        : ''
      }
     </div>
   </div>
  </div>

    { show === true ?
     <div id="popup">
        <div id="login-form">
          { toggle === 'switch to register' ?
            <form onSubmit={handleSubmit}>
               <h3 style={{marginBottom: '7px'}}>Create New Account!</h3>
               <p className="already">Already have an account? <span onClick={() => setToggle('switch to login')}>Login in</span></p>
                    <span className="closeLogin" onClick={() => {setLogin({...login, submit: false, status : false, invalid: false, verification: false, account : '', userID : 0, user_email: '', user_exist : false, user_exist_text : 'Email already exists'}); setShow(false); if(document.body.hasAttribute('style')){document.body.removeAttribute('style');} }}><CloseIcon/></span> 

              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
              </div>
              
              <div className="inputBox">
                <input type="password" placeholder="Password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
              </div>

              <div className="inputBox">
                <input type="password" placeholder="Confirm password" onChange={(e) => setUser({ ...user, cnf_password: e.target.value })} />
              </div>

              <button type="submit">SIGN UP</button>
              { login.submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting...</p> 
                : ''
              }
              { login.account === 'pending' ?
                <>
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />awaiting email verification....</p>
                 <p id="resend-verification" style={{color: '#fff'}}><span onClick={resendEmailVerification}>Resend email verification</span></p>
                </>
                : ''
              }
              { login.account === 'active' ?
                <p id="verfied" style={{color: '#fff'}}>Account activated</p> 
                : ''
              }
              <div className="form-footer">
                <span className="left-foot">All fields are required.</span>
                <span className="right-foot" onClick={() => setToggle('switch to login')}><LockIcon/> Login</span> 
              </div>
            </form>
            : ''
            }

            { toggle === 'switch to login' ?
             <form onSubmit={handleSubmitLogin}>
              <h3>Welcome Back!</h3>
                            <span className="closeLogin" onClick={() => { setShow(false); if(document.body.hasAttribute('style')){document.body.removeAttribute('style');} }}><CloseIcon/></span> 

              <div className="inputBox">
                <input className="form-control" type="text" placeholder="Enter your email" onChange={(e) => setUser({ ...user, email: e.target.value })} />
                <span id="text" style={{color: 'rgb(76, 175, 80)', display: 'none'}}>Your Email Address is Valid.</span>
              </div>
              
              <div className="inputBox">
                 <input type="password" placeholder="Enter your password" onChange={(e) => setUser({ ...user, password: e.target.value })} />
              </div>
              <div id="forgot-pass">
                <span onClick={forgotPassword}>Forgot Password</span> 
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>LOGIN</button>
              { login.submit === true ?
                <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                : ''
              }
              { login.user_exist === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>{login.user_exist_text}</p> 
                : ''
              }
              { login.account === 'active' ?
                <p id="verfied" style={{color: '#fff'}}>Login Success</p> 
                : ''
              }
              { login.invalid === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Invalid username or password</p> 
                : ''
              }
              { login.verification === true ?
                <p style={{ color : 'red', textAlign: 'center', fontSize: '14px', marginTop: '15px'}}>Email verification is pending</p> 
                : ''
              }
              <div className="form-footer">
                              <span className="left-foot">All fields are required.</span>
                              <span className="right-foot" onClick={() => setToggle('switch to register')}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
            : ''
          }

          { forgotPass === true ?
                       <form onSubmit={sendOTP}>
              <h3>Forgot Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>Submit your email id, we will send an otp for resetting new password.</p>
                            <span className="closeLogin" onClick={() => {setShow(false);setForgotPass(false); if(document.body.hasAttribute('style')){document.body.removeAttribute('style');} }}><CloseIcon/></span>
              <div className="inputBox">
                <input type="text" placeholder="Enter your email" onChange={(e) => setForgotPassEmail(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SEND OTP</button>
              { forgot_pass_submit === true ?
                               <p id="waiting" style={{color: '#fff'}}><img src={wait} />Sending OTP....</p> 
                              : '' 
              }
              <div className="form-footer">
                              <span className="left-foot">All fields are required.</span>
                              <span className="right-foot" onClick={() => {setToggle('switch to register');setForgotPass(false)}}><AccountCircleIcon/> Sign Up</span> 
              </div>
            </form>
                       : ''
          }
          { resetPass === true ?
                       <form onSubmit={handleResetPassword}>
              <h3>Reset Password</h3>
              <p style={{textAlign: 'center', color: '#fff', fontSize: '13px', marginBottom: '25px'}}>We have sent an OTP to your email id, Please enter otp and set new password</p>
                            <span className="closeLogin" onClick={() => {setShow(false);setResetPass(false); if(document.body.hasAttribute('style')){document.body.removeAttribute('style');} }}><CloseIcon/></span>
              <div className="inputBox">
                 <input type="text" placeholder="Enter OTP" onChange={(e) => setResetPassOTP(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="password" placeholder="Enter New Password" onChange={(e) => setResetPassNew(e.target.value)} />
              </div>
              <div className="inputBox">
                 <input type="text" placeholder="Confirm New Password" onChange={(e) => setResetPassConfirm(e.target.value)} />
              </div>
              <button type="submit" style={{background: '#9b5f07'}}>SUBMIT</button>
              { reset_pass_submit === true ?
                               <p id="waiting" style={{color: '#fff'}}><img src={wait} />Submitting....</p> 
                              : '' 
              }
              { resetPassSuccess === true ?
                <p style={{color: '#5bed61', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>New Password set Successfully, <span style={{cursor: 'pointer', }} onClick={() => {setResetPass(false);setToggle('switch to login'); }}>Login</span></p>  
                : ''  
              }
              { resetPassFailure === true ?
                <p style={{color: 'red', fontSize: '13px', textAlign: 'center', marginTop: '20px'}}>Invalid OTP, try again</p>    
                              : ''
              }
            </form> 
            : ''
          }
        </div>
     </div>
      : ''
}

                            </div>
                        </div>
                    </div>
                </div>
                <div id="description"><InfoIcon/> This is a tool humanizes AI content, improves quality, restructures it to improve readability and flow. It is based on an advanced AI model which can work to improve GPT 2,3 and 3.5,4. Our content is proven to rank better for SEO and has no grammatical errors</div>
            </div>
        </div>

          

        <div className="overlay toggle-icon"></div>
        <button id="myclick" style={{visibility: 'hidden', height: '0'}}>myclick</button>
        <a href="javaScript:;" className="back-to-top"><i className='bx bxs-up-arrow-alt'></i></a>
        <footer className="page-footer">
            <p className="mb-0">Copyright ©. All right reserved.</p>
        </footer>
    </div>

        <div className="switcher-wrapper">
        <div className="switcher-btn"> <i className='bx bx-cog bx-spin'></i>
        </div>
        <div className="switcher-body">
            <div className="d-flex align-items-center">
                <h5 className="mb-0 text-uppercase" style={{fontSize: '1rem'}}>Change Background</h5>
                <button type="button" className="btn-close ms-auto close-switcher" aria-label="Close"></button>
            </div>
            <hr/>
            <p className="mb-0">Gaussian Texture</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme1"></li>
                <li id="theme2"></li>
                <li id="theme3"></li>
                <li id="theme4"></li>
                <li id="theme5"></li>
                <li id="theme6"></li>
              </ul>
               <hr/>
              <p className="mb-0">Gradient Background</p>
              <hr/>
              
              <ul className="switcher">
                <li id="theme7"></li>
                <li id="theme8"></li>
                <li id="theme9"></li>
                <li id="theme10"></li>
                <li id="theme11"></li>
                <li id="theme12"></li>
                <li id="theme13"></li>
                <li id="theme14"></li>
                <li id="theme15"></li>
              </ul>
        </div>
    </div>



  <div className="toast" id="toast-copy">
    <div className="toast-content">
      <div className="message">
        <span className="message-text text-2"><DoneIcon/> Text Copied</span>
      </div>
    </div>
    <div className="progress" id="progress-copy"></div>
  </div>

  <div className="toast" id="toast-logout">
    <div className="toast-content">
      <div className="message">
        <span className="message-text text-2"><DoneIcon/> Logged Out</span>
      </div>
    </div>
    <div className="progress" id="progress-logout"></div>
  </div>

  <div className="toast" id="toast-error">
    <div className="toast-content">
      <div className="message">
        <span className="message-text text-2"><DoneIcon/> Sorry the text is not processed</span>
      </div>
    </div>
    <div className="progress" id="progress-error"></div>
  </div>

  <div className="toast" id="toast-word-limit">
    <div className="toast-content">
      <div className="message">
        <span style={{cursor: 'pointer'}} className="message-text text-2" onClick={() => {setProModal(true); setShowBack(false); setSecondPro(true);}}><DoneIcon/> Upgrade to increase word limit</span>
      </div>
    </div>
    <div className="progress" id="progress-word-limit"></div>
  </div>

   <div id="pro-popup" className={ promodal === true ? "open-pro" : ""}>
    <div id="pro-popup-main">
       <div id="pro-popup-inner">
         { secondPro === true ?
             <div id="second-pro">
               { showback === true ?
                 <span id="backTofirst" onClick={() => {setShowBack(false); setSecondPro(false);}}><ArrowBackIosIcon/></span>
                 : ''
               }
               <h3>Upgrade to Premium</h3>
               <ul>
                 <li><CheckCircleOutlineIcon/> Increased Speed</li>
                 <li><CheckCircleOutlineIcon/> Premium Support</li>
               </ul>
               <div id="plans">
                 { has_subscription === true ? 
                  <Card>
                   <h4>Your Current Plan</h4><br></br>
                   <p className="plan-card"><span>Monthly</span> Plan</p>
                   <p className="features">
                      Words availbe: {account_status.credits_availbe.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <br></br>
                      Next Payment: {account_status.subscription_renewal_date}
                   </p>
                   <p className="plan-amnt">
                     <span>$</span>
                     <span>{account_status.subscription_amount}</span>
                     <span>/month</span>
                   </p>
                  </Card>
                   : 
                  <div className={'plan' + (plan.monthly === true ? ' active' : '')} onClick={() => setPlan({...plan, monthly: true, onetime: false})}>
                   <div id="selected_plan"><CheckCircleOutlineIcon/></div>
                   <p>Monthly Plan</p>
                   <span style={{fontSize: '11px', color: '#FFC107'}}>*Access to Lightning Mode (Unlimited Words)</span><br></br>
                   <p className="sub_price"><span>${price_show}</span> ${price_show - price_show*discount_applied.value/100}</p>
                   <div id="subscription">
                    <select onChange={(e) => setSubscription(e.target.value)}>
                       {products && products
                          .filter((product) => product.currency === 'usd' && product.is_subscription === 1)
                          .map((filteredProduct) => 
                            <option value={filteredProduct.price_id}>{filteredProduct.credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' Words (Premium Mode)'}</option>
                          )
                       }
                    </select>
                   </div>
                   <br></br><span style={{fontSize: '11px'}}>*Cancel anytime</span><br></br>
                 </div>
                 }

                 <div className={'plan' + (plan.onetime === true ? ' active' : '')} onClick={() => setPlan({...plan, monthly: false, onetime: true})}>
                   <div id="selected_plan"><CheckCircleOutlineIcon/></div>
                   <p>One Time Purchase</p>
                   <span style={{fontSize: '11px'}}>*Word Balance has lifetime validity</span><br></br>
                   <span style={{fontSize: '11px'}}>*No renewals required; it's a single purchase</span>
                   <p className="sub_price"><span>${price_show_onetime}</span> ${price_show_onetime - price_show_onetime*discount_applied.value/100}</p>
                   <div id="onetime">
                     <select onChange={(e) => setOneTimePurchase(e.target.value)}>
                          {products && products
                            .filter((product) => product.currency === 'usd' && product.is_subscription === 0)
                            .map((filteredProduct) => 
                               <option value={filteredProduct.price_id}>{filteredProduct.credits.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' Words (Premium Mode)'}</option>
                            )
                          }
                     </select>
                    </div>
                 </div>
              </div>
              <p style={{fontSize: '12px', marginBottom: '9px'}}>Humanizing a 200-word article will now use only 200 words from your word balance</p>
              <p id="auto-applied"><LoyaltyIcon/> {promocode_applied_text}</p>
              <div id="promocode">
                { promo_status === false ? <p style={{fontSize: '12px', color: 'red'}}>Promocode does not exist</p> : ''}
                <input type="text" value={promocode} onChange={(e) => {setPromoCode(e.target.value); setDiscountApplied({...discount_applied, status: false });}} placeholder="Enter prompo code"/>
                <button type="button" onClick={applyCoupon} className={ discount_applied.status === true ? 'apply_button active' : 'apply_button'}>
                  { discount_applied.status === true ? 'Applied' : 'Apply' }
                </button>
              </div>
              { plan.monthly === true && plan.onetime === false ?
                <form action="https://oneclickhuman.com/api_request/create-checkout-session" method="POST">
                  <input type="hidden" name="email_address" value={login.user_email} />
                  <input type="hidden" name="subscription" value={subscription} />
                  <input type="hidden" name="promocode" value={promocode} />
                  <button id="checkout-and-portal-button" type="submit">Purchase</button>
                </form> 
                : 
                <form action="https://oneclickhuman.com/api_request/create-checkout-session-onetime" method="POST">
                  <input type="hidden" name="email_address" value={login.user_email} />
                  <input type="hidden" name="onetime_purchase" value={onetime_purchase} />
                  <input type="hidden" name="promocode" value={promocode} />
                  <button id="checkout-and-portal-button" type="submit">Purchase</button>
                </form> 
              }

              <p id="powered-by">( powered by <img src={stripe} style={{width : '30px', verticalAlign: 'middle' }}/> )</p>
         </div>
          : <div id="first-pro">
           <img src={expired} style={{width: '100px'}}/>
           <h3>Daily Word Balance Exhausted</h3>
           <p>Word Balance will refill in</p>
           <div id="pro-counter">
             {countdown}       
           </div>
           <button onClick={() => {setSecondPro(true); setShowBack(true); }}><LockOpenIcon/> Unlock Words</button>

         </div> }

       </div>
       <span id="pro-close" onClick={() => setProModal(false)}><CloseIcon/></span>
    </div>
  </div>

  <div id="pro-popup" className={ underConstruction === true ? "open-pro" : ""}>
    <div id="pro-popup-main">
       <div id="pro-popup-inner">
          <p>We apologize for the inconvenience, but our website is currently undergoing scheduled maintenance. We are working hard to improve our services and will be back in few hours.
          <br/><br/>
          Thank you for your patience and understanding.</p> 
       </div>
       <span id="pro-close" onClick={() => setUnderConstruction(false)}><CloseIcon/></span>
    </div>
  </div>

    </>
  );
}

export default App;

const Card = styled.div`
   width:100%;
   padding: 20px;
   border-radius: 15px;
   position: relative;
   margin-bottom: 40px;
   text-align: left !important;
   margin: 10px;
   border: 1px solid #585656;

   h4 {
    font-size: 15px;
}

   .features {
    font-size: 14px;
    line-height: 27px;
    color: #fff;
   }

   .plan-card {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 25px;

    span {
      border-radius: 8px;
      background: #fff;
      color: #000;
      padding: 5px 12px;
      margin-right: 5px;
    }
   }

   .plan-amnt {
      position: absolute;
      top: 20px;
      right: 20px;
      line-height: 44px;

      span:nth-child(1) {
        font-size: 14px;
        vertical-align: top;
      }

      span:nth-child(2) {
        font-size: 24px;
        font-weight: 600;
      }

      span:nth-child(3) {
        font-size: 13px;
        color: #777;
        vertical-align: top;
      }
    }

   button {
    background: #2196f3;
    color: #fff;
    border: 0;
    border-radius: 4px;
    position: absolute;
    bottom: 20px;
    right: 20px;
    width: 145px;
    height: 40px;
    cursor: pointer;

     svg { vertical-align: middle; }
   }
`;